<template>
  <div class="page1">
    <div class="content">
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
          :rules="rule"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item label="会员电话">
                <el-input
                  type="text"
                  v-model="formInline.mobile"
                  placeholder="请输入"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  maxlength="11"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Bind_vehicle')">
                <el-select v-model.trim="formInline.memberCarStatus" size="15">
                  <el-option
                    v-for="(item, index) in selectList"
                    :label="item.label"
                    :value="item.value"
                    :key="index"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Certified_vehicles')">
                <el-select v-model.trim="formInline.authCarStatus" size="15">
                  <el-option
                    v-for="(item, index) in selectList"
                    :label="item.label"
                    :value="item.value"
                    :key="index"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.arrears')">
                <el-input
                  type="text"
                  v-model="formInline.debtMoneyStart"
                  style="width: 120px"
                  placeholder="请输入"
                  maxlength="7"
                  oninput="value=value.replace(/[^\d]/g,'')"
                ></el-input
                ><span class="mglr8">至</span>
                <el-input
                  type="text"
                  v-model="formInline.debtMoneyEnd"
                  style="width: 120px"
                  placeholder="请输入"
                  maxlength="7"
                  oninput="value=value.replace(/[^\d]/g,'')"
                ></el-input
                ><span class="mgl8">元</span>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Number_of_outstanding_payments')">
                <el-input
                  type="text"
                  v-model="formInline.debtOrderNumStart"
                  style="width: 120px"
                  placeholder="请输入"
                  maxlength="7"
                  oninput="value=value.replace(/[^\d]/g,'')"
                ></el-input
                ><span class="mglr8">至</span>
                <el-input
                  type="text"
                  v-model="formInline.debtOrderNumEnd"
                  style="width: 120px"
                  placeholder="请输入"
                  maxlength="7"
                  oninput="value=value.replace(/[^\d]/g,'')"
                ></el-input
                ><span class="mgl8">笔</span>
              </el-form-item>
              <el-form-item :label="$t('searchModule.phone_arrears_recovery')">
                <el-input
                  type="text"
                  v-model="formInline.mobileRecoverNumStart"
                  style="width: 120px"
                  placeholder="请输入"
                  maxlength="7"
                  oninput="value=value.replace(/[^\d]/g,'')"
                ></el-input
                ><span class="mglr8">至</span>
                <el-input
                  type="text"
                  v-model="formInline.mobileRecoverNumEnd"
                  style="width: 120px"
                  placeholder="请输入"
                  maxlength="7"
                  oninput="value=value.replace(/[^\d]/g,'')"
                ></el-input
                ><span class="mgl8">次</span>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
              <el-button type="info" icon="el-icon-delete" @click="resetForm()">清空</el-button>
            </div>
          </div>
          <div class="col_box_boder" v-if="$route.meta.authority.button.export"></div>
          <div class="col_box h44">
            <div class="col_left"></div>
            <div class="col_right mbd4">
              <exportFile
                @exportFile="handleExportFile"
                style="display: inline-block"
                :exportData="exportData"
              ></exportFile>
            </div>
          </div>
        </el-form>
      </div>
      <div class="tableWrapper paddingB10 bgFFF">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <!--<el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>-->
          <el-table-column
            v-for="item in tableCols"
            :key="item.prop"
            :prop="item.prop"
            align="center"
            :label="item.label"
            :width="item.width"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div v-if="item.type == 'phone'">
                <table-phone
                  :row="scope.row"
                  :isSuceess="scope.row[item.showParams.statusKey] == 1"
                  :phone="scope.row[item.prop]"
                  :tips="scope.row[item.showParams.textKey]"
                />
              </div>
              <div v-else>
                <p>
                  {{ item.formatter ? item.formatter(scope.row) : scope.row[item.prop] }}
                </p>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="90">
            <template slot-scope="scope">
              <el-button type="text" @click="handleDetail(scope.row)">欠费详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <arrearage-details
      :detailShow="detailShow"
      :cardIds="cardIds"
      :rowData="rowData"
      @close="detailShow = false"
    />
  </div>
</template>
<script>
import exportFile from "@/components/exportFile/exportFile.vue";
import arrearageDetails from "@/components/arrearage/arrearageDetails.vue";
import tablePhone from "@/components/tablePhone/tablePhone.vue";
import { compareSize } from "@/common/js/utils.js";
export default {
  data() {
    return {
      exportData: {},
      formInline: {
        mobile: "",
        memberCarStatus: "",
        authCarStatus: "",
        debtMoneyStart: "",
        debtMoneyEnd: "",
        debtOrderNumStart: "",
        debtOrderNumEnd: "",
        mobileRecoverNumStart: "",
        mobileRecoverNumEnd: "",
      },
      rule: {},
      selectList: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "有",
          value: "1",
        },
        {
          label: "无",
          value: "0",
        },
      ],
      loading: false,
      total: 0,
      pageSize: 15,
      pageNum: 1,
      tableData: [],
      tableCols: [
        {
          prop: "mobile",
          label: "会员电话",
          type: "phone",
          width: "150",
          showParams: {
            statusKey: "memberPhoneStatus",
            textKey: "memberPhoneStatusDesc",
          },
        },
        {
          prop: "debtMoney",
          label: this.$t("list.arrears"),
          width: "",
          formatter: (row, column) => {
            if (row.debtMoney) {
              if (row.debtMoney >= 0) {
                return Number(row.debtMoney / 100).toFixed(2) + "元";
              } else {
                return row.debtMoney + "元";
              }
            } else {
              return "0.00元";
            }
          },
        },
        {
          prop: "debtOrderNum",
          label: "欠费笔数",
          width: "",
        },
        {
          prop: "mobileRecoverNum",
          label: "电话追缴次数",
          width: "",
        },
        {
          prop: "messageRecoverNum",
          label: "短信追缴次数",
          width: "",
        },
        {
          prop: "authPlateNumbers",
          label: "认证车辆",
          width: "",
          // formatter: (row, column) => {
          //   if (row.authPlateNumbers.indexOf("无") != -1) {
          //     return "无牌车";
          //   } else {
          //     return row.authPlateNumbers;
          //   }
          // }
        },
        {
          prop: "memberPlateNumbers",
          label: "绑定车辆",
          width: "",
          // formatter: (row, column) => {
          //   if (row.memberPlateNumbers.indexOf("无") != -1) {
          //     return "无牌车";
          //   } else {
          //     return row.memberPlateNumbers;
          //   }
          // }
        },
      ],
      detailShow: false,
      rowData: {},
      cardIds: "",
    };
  },
  created() {
    this.handleExportFile();
    this.searchData();
  },
  methods: {
    handleDetail(row) {
      this.rowData = row;
      this.cardIds = row.memberCarIds || "";
      this.detailShow = true;
    },
    searchData() {
      const {
        debtMoneyStart,
        debtMoneyEnd,
        debtOrderNumStart,
        debtOrderNumEnd,
        mobileRecoverNumStart,
        mobileRecoverNumEnd,
      } = this.formInline;
      if (!compareSize(debtMoneyStart, debtMoneyEnd, "欠费金额")) return;
      if (!compareSize(debtOrderNumStart, debtOrderNumEnd, "欠费笔数")) return;
      if (!compareSize(mobileRecoverNumStart, mobileRecoverNumEnd, "电话追缴次数")) return;
      this.loading = true;
      let opt = {
        method: "get",
        url: "/acb/2.0/debtMember/list",
        data: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          ...this.formInline,
          debtMoneyStart: this.formInline.debtMoneyStart * 100 || "",
          debtMoneyEnd: this.formInline.debtMoneyEnd * 100 || "",
        },
        success: (res) => {
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1 || 0;
          } else {
            this.$alert(res.msg, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
          this.loading = false;
        },
        fail: () => {
          this.loading = false;
        },
      };
      this.$request(opt);
    },
    resetForm() {
      this.formInline = {
        mobile: "",
        memberCarStatus: "",
        authCarStatus: "",
        debtMoneyStart: "",
        debtMoneyEnd: "",
        debtOrderNumStart: "",
        debtOrderNumEnd: "",
        mobileRecoverNumStart: "",
        mobileRecoverNumEnd: "",
      };
    },
    handleExportFile() {
      let data = JSON.parse(JSON.stringify(this.formInline));
      data.debtMoneyStart = data.debtMoneyStart ? data.debtMoneyStart * 100 : data.debtMoneyStart;
      data.debtMoneyEnd = data.debtMoneyEnd ? data.debtMoneyEnd * 100 : data.debtMoneyEnd;
      // 导出数据
      this.exportData = {
        url: "/acb/2.0/debtMember/export",
        methods: "post",
        data: {
          name: "欠费用户",
          ...data,
        },
      };
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
  },
  components: {
    exportFile,
    arrearageDetails,
    tablePhone,
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.mglr8 {
  margin: 0 8px;
}

.mgl8 {
  margin-left: 8px;
}

.content {
  overflow: hidden;



  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;
      border: 1px solid #0099FA;
      border-radius: 4px;
      color: #0099FA;
      padding: 8px 13px;
      cursor: pointer;

      .iconfont {
        margin-right: 14px;
      }
    }
  }
}
</style>
